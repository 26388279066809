<script>
    import {HorizontalBar} from "vue-chartjs"

    export default {
        extends: HorizontalBar,
        props: {
            options: {
                type: Object
            },
            dataInput: {
                type: Object,
                default: () => {}
            }
        },
        watch: {
            dataInput(value) {

                const colorsArray = ['rgb(255, 99, 132)', 'rgb(54, 162, 235)', 'rgb(255, 205, 86)', 'rgb(55, 199, 132)', 'rgb(25, 99, 232)', 'rgb(200, 29, 102)', 'rgb(155, 239, 12)' ]
                const colorsLength = colorsArray.length
                const dataLength = Object.keys(value).length

                if (colorsLength < dataLength) {
                    for (let i = colorsLength; i < dataLength; i++) {
                        const newColor = `#${Math.floor(Math.random()*16777215).toString(16)}`
                        colorsArray.push(newColor)
                    }
                }

                Chart.defaults.global.legend.display = false;
                this.renderChart({
                    labels: Object.keys(value),
                    datasets: [{
                        label: 'data',
                        data: Object.values(value),
                        backgroundColor: colorsArray,
                    }]
                }, {
                    ...this.options,
                    scales: {
                        xAxes: [{
                            ticks: {
                                beginAtZero: true
                            }
                        }]
                    }
                })

            }
        }
    }
</script>