<script>
  import {Line} from "vue-chartjs"

  export default {
    extends: Line,
    props: {
      options: {
        type: Object
      },
      dataInput: {
        type: Object,
        default: () => {}
      },
      date_selected: {
        type: String
      }
    },
    mounted() {
      // this.renderChart({
      //   labels: ['uno', 'dos', 'tres','cuatro','cinco','seis','siete','ocho','nueve','diez','once','doce'],
      //   datasets: [{
      //     label: 'data',
      //     data: [100, 0, 20, 45, 75, 30, 15, 110, 60, 145, 175, 55],
      //     pointBackgroundColor:'rgb(0,255,255)',
      //     borderColor: 'rgb(255, 99, 132)',
      //     pointRadius: 5,
      //     fill: false
      //   }]
      // }, this.options)
    },
    watch: {
      dataInput(dataObject) {
        if (dataObject) {
          let values
          let ordered_keys
          if (this.date_selected === 'today' || this.date_selected === 'month') {

            const object_keys = Object.keys(dataObject)
  
            const mapped = object_keys.map((el, i) => {
              console.log("el: ", el);
              console.log("i: ", i);
              return { index: i, value: el };
            })
  
            mapped.sort((a,b) => {
              if (a.value > b.value) { return 1; }
              if (a.value < b.value) { return -1; }
              return 0;
            })
  
            ordered_keys = mapped.map(function(el) {
              return object_keys[el.index];
            });
            // console.log(ordered_keys);
  
            values = ordered_keys.map(el => dataObject[el])
            // console.log(values);
  
          }


          this.renderChart({
            labels: ordered_keys? ordered_keys : Object.keys(dataObject),
            datasets: [{
              label: 'Mensajes enviados',
              data: values? values : Object.values(dataObject),
              // pointBackgroundColor:'rgb(0,255,255)',
              pointBackgroundColor:'rgb(55, 199, 132)',
              // borderColor: 'rgb(255, 99, 132)',
              borderColor: '#836af9',
              pointRadius: 5,
              fill: false
            }]
          }, 
          {
            ...this.options,
            scales: {
              yAxes: [{
                ticks: {
                  // stepSize: 1,
                  min: 0
                }
              }]
            }
          })
        }
      }
    },

  }
</script>