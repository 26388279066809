<template>
  <div>
    <div class="d-flex align-center justify-center flex-wrap">
      <v-row class="justify-center mx-3 mb-8">
        <v-col cols="12" class="px-0">
          <v-card>

            <v-row>
              <v-col cols="6">
                <v-card-title class="align-start">
                  Estadísticas
                </v-card-title>
                <v-card-text class="pb-0 d-flex align-center">
                  <span>Última actualización: {{ globalData.last_update? globalData.last_update : '-' }}</span>

                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="ml-2"
                        color="primary"
                        dense
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ icons.mdiInformationOutline }}
                      </v-icon>
                    </template>
                    <span>Las estadísticas son actualizadas en intervalos de una hora.</span>
                  </v-tooltip>


                </v-card-text>
              </v-col>
              <v-col cols="6" class="d-flex justify-end align-end">
                <v-col cols="10" align-self="end">
                  <v-select
                    outlined
                    dense
                    filled
                    hide-details
                    v-model="date_selected"
                    label="Filtro"
                    :disabled="!getSelectedCompany"
                    :items=options_date
                    @change="callEveryStats()"
                  />
                </v-col>
              </v-col>
            </v-row>

            <v-divider class="mt-4"></v-divider>
            <v-card-text>
              <v-row>
                <v-col
                  cols="6"
                  md="6"
                >
                  <div class="d-flex">
                    <v-avatar
                      size="44"
                      color="primary"
                      rounded
                      class="elevation-1"
                    >
                      <v-icon
                        dark
                        color="white"
                        size="30"
                      >
                        {{ icons.mdiHomeCityOutline }}
                      </v-icon>
                    </v-avatar>
                    <div class="ms-3">
                      <p class="text-xs mb-0">
                        Empresa
                      </p>
                      <span class="text--primary text-xl font-weight-bold">
                        {{
                          globalData.company_name
                            ? globalData.company_name === 'all'? 'Todas' : globalData.company_name
                            : '-'
                        }}
                      </span>
                    </div>
                  </div>
                </v-col>
                <v-col
                  cols="6"
                  md="6"
                >
                  <div class="d-flex">
                    <v-avatar
                      size="44"
                      color="warning"
                      rounded
                      class="elevation-1"
                    >
                      <v-icon
                        dark
                        color="white"
                        size="30"
                      >
                        {{ icons.mdiEmailCheckOutline }}
                      </v-icon>
                    </v-avatar>
                    <div class="ms-3">
                      <p class="text-xs mb-0">
                        Mensajes Envíados
                      </p>
                      <span class="text--primary  text-xl font-weight-bold">
                        {{
                          globalData.messages_count
                            ? globalData.messages_count === 'all'? 'Todas' : globalData.messages_count
                            : '-'
                        }}
                      </span>
                    </div>
                  </div>
                </v-col>
                <v-col
                  cols="6"
                  md="6"
                >
                  <div class="d-flex">
                    <v-avatar
                      size="44"
                      color="success"
                      rounded
                      class="elevation-1"
                    >
                      <v-icon
                        dark
                        color="white"
                        size="30"
                      >
                        {{ icons.mdiAccountOutline }}
                      </v-icon>
                    </v-avatar>
                    <div class="ms-3">
                      <p class="text-xs mb-0">
                        Usuarios
                      </p>
                      <span class="text--primary text-xl font-weight-bold">
                        {{
                          typeof globalData.workers === 'number'
                            ? globalData.workers
                            : '-'
                        }}
                      </span>
                    </div>
                  </div>
                </v-col>
                <v-col
                  cols="6"
                  md="6"
                >
                  <div class="d-flex">
                    <v-avatar
                      size="44"
                      color="info"
                      rounded
                      class="elevation-1"
                    >
                      <v-icon
                        dark
                        color="white"
                        size="30"
                      >
                        {{ icons.mdiCurrencyUsd }}
                      </v-icon>
                    </v-avatar>
                    <div class="ms-3">
                      <p class="text-xs mb-0">
                        Créditos
                      </p>
                      <span class="text--primary text-xl font-weight-bold">
                        {{
                          globalData.credits
                            ? globalData.credits === 'all'? 'Todas' : globalData.credits
                            : '-'
                        }}
                      </span>
                    </div>
                  </div>
                </v-col>
              </v-row>


            </v-card-text>
          </v-card>

          <v-col cols="12" class="mt-2 mb-0" v-if="!getSelectedCompany">
            <v-alert
              dense
              border="left"
              type="warning"
            >
              No hay empresa <strong>seleccionada</strong>
            </v-alert>
          </v-col>

        </v-col>
      </v-row>

    </div>


    <div class="single-row-chart mb-8">
      <v-card
        class="text-center"
        height="100%"
        width="100%"
      >
        <v-row class="justify-center">
          <v-col
          cols="4"
          class="d-flex justify-center ">
            <p class="font-weight-medium pa-2 mb-0">Mensajes Respondidos</p>
          </v-col>
        </v-row>
        <div class="mb-4">

          <DoughnutChart
            v-show="sended_messages_data.length"
            ref="messages_doughnut"
            :options="chartOptions"
            :dataInput="sended_messages_data"
          />
          <div v-if="!sended_messages_data.length" class="d-flex align-center justify-center empty-graph">No hay mensajes</div>
        </div>
      </v-card>
    </div>

    <div class="d-flex align-center justify-center justify-md-space-between flex-md-nowrap flex-wrap mb-10">
          <div class="barchart-container" v-if="getUserRole !== 2" :class="{ 'd-none' : getSelectedCompany !== 'all' }">
            <v-card
              height="100%"
              width="100%"
              class="d-flex align-center justify-center flex-column flex-wrap"
            >
              <v-row class="align-center justify-center">
                <p class="font-weight-medium pa-2 mb-2">Ranking de empresas</p>
              </v-row>
              <div class="pb-4" style="width: 90%">
                <BarChart
                  v-show="!isEmptyObject(bar_chart_companies_data)"
                  :dataInput="bar_chart_companies_data"
                  :options="chartOptions"
                />
                <div v-if="isEmptyObject(bar_chart_companies_data)" class="d-flex align-center justify-center empty-graph">No hay empresas</div>
              </div>
            </v-card>
          </div>

          <div class="barchart-container" :class="{ 'w-full' : getSelectedCompany !== 'all' }">
            <v-card
              height="100%"
              width="100%"
              class="d-flex align-center justify-center flex-column flex-wrap "
            >
              <v-row class="align-center justify-center">
                <p class="font-weight-medium pa-2 mb-2" >Ranking de usuarios</p>
              </v-row>
              <div class="pb-4" style="width: 90%">
                <BarChart
                  v-show="!isEmptyObject(bar_chart_users_data)"
                  :options="chartOptions"
                  :dataInput="bar_chart_users_data"
                />
                <div v-if="isEmptyObject(bar_chart_users_data)" class="d-flex align-center justify-center empty-graph">No hay usuarios</div>
              </div>
            </v-card>
          </div>
    </div>

    <div class="single-row-chart">
      <v-card
        class="text-center"
        height="100%"
        width="100%"
      >
        <v-row class="justify-center">
          <v-col
          cols="4"
          class="d-flex justify-center">
            <p class="font-weight-medium pa-2 mb-0">Mensajes Enviados</p>
          </v-col>
        </v-row>
        <div class="mb-4">
          <LineChart
            v-show="!isEmptyObject(messages_in_linetime_data)"
            :options="chartOptions"
            :dataInput="messages_in_linetime_data"
            :date_selected="date_selected"
          />
          <div v-if="isEmptyObject(messages_in_linetime_data)" class="d-flex align-center justify-center empty-graph">No hay mensajes envíados</div>
        </div>
      </v-card>
    </div>

  </div>
</template>

<script>

  import BasicStatCard from '@/components/Stats/BasicStatCard';
  import DoughnutChart from '@/components/Stats/DoughnutChart';
  import BarChart from '@/components/Stats/BarChart';
  import LineChart from '@/components/Stats/LineChart';
  import {
    mdiAccountOutline,
    mdiCurrencyUsd,
    mdiHomeCityOutline,
    mdiDotsVertical,
    mdiEmailCheckOutline,
    mdiInformationOutline,
  } from '@mdi/js';

  export default {

    data: () => {
    return {
      currentCompanyName:'',
      options_date:[
        { text: 'Todos', value: 'all' },
        { text: 'Hoy', value: 'today' },
        { text: 'Esta semana', value: 'week' },
        { text: 'Este mes', value: 'month' },
      ],
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },

      date_selected: 'all',

      globalData: {},
      sended_messages_data: [],

      bar_chart_users_data: {},

      bar_chart_companies_data: {},

      messages_in_linetime_data: {}
    }
  },

    mounted() {
      if (this.getSelectedCompany) {
        this.callEveryStats()
      }
    },
    // beforeDestroy() {
    //   if (this.getSelectedCompany === 'all') this.$store.dispatch('session/setSelectedCompany', false)
    // },

    methods: {
      callEveryStats() {
        const filters = {
          date: this.date_selected,
          company_id: this.getSelectedCompany
        }

        this.getMessagesStats(filters)
        this.getSendedMessagesStats(filters)
        this.getStatsForDashboardCompanies(filters)
        this.getStatsByUser(filters)
        this.getStatsByCompany(filters)
      },

      async getMessagesStats(filters) {
        try {
          const response = await this.$api.getMessagesStats(filters)

          // Doughnut
          this.sended_messages_data = [
            response.not_respond,
            response.respond_with_ok,
            response.respond_with_down
          ]

          if (this.sended_messages_data[0] === 0 && this.sended_messages_data[1] === 0 && this.sended_messages_data[1] === 0) {
            this.sended_messages_data = []
          }

        } catch (e) {
          console.log(e);
        }
      },

      async getSendedMessagesStats(filters) {
        try {
          const response = await this.$api.getSendedMessagesStats(filters)

          this.messages_in_linetime_data = response
          // if (this.date_selected === 'today' || this.date_selected === 'month') {
          //   this.messages_in_linetime_data = {"08":58,"09":150,"10":86,"11":15}
          // }

        } catch (e) {
          console.log(e);
        }
      },

      async getStatsForDashboardCompanies(filters) {
        try {
          const response = await this.$api.getStatsForDashboardCompanies(filters)
          this.globalData = response
        } catch (e) {
          console.log(e);
        }
      },

      async getStatsByUser(filters) {
        try {
          const response = await this.$api.getStatsByUser(filters)
          this.bar_chart_users_data = response

        } catch (e) {
          console.log(e);
        }
      },

      async getStatsByCompany(filters) {
        try {
          const response = await this.$api.getStatsByCompany(filters)
          this.bar_chart_companies_data = response
        } catch (e) {
          console.log(e);
        }
      },

      isEmptyObject(object) {
        return !Object.keys(object).length
      },

    },

  computed: {
    getSelectedCompany() { return this.$store.getters['session/getSelectedCompany']() },
    getUserRole() { return this.$store.getters['session/getUserRole']() },
  },

  watch: {
    getSelectedCompany(newValue) {
      if (newValue) {
        // this.getCompanies()
        // this.getStatsData()

        this.callEveryStats()
      }
    }
  },

  components: {
    BasicStatCard,
    DoughnutChart,
    BarChart,
    LineChart
  },

setup() {
    return {
      // icons
      icons: {
        mdiDotsVertical,
        mdiHomeCityOutline,
        mdiAccountOutline,
        mdiEmailCheckOutline,
        mdiCurrencyUsd,
        mdiInformationOutline
      },
    }
  },
  }
</script>

<style scoped>
  .barchart-container {
    height: 490px;
    width: 650px;
    margin-right: 12px;
    margin-left: 12px
  }

  @media (max-width: 960px) {
    .barchart-container {
      width: 100%;
    }
  }

  .empty-graph {
    height: 400px;
    background-color: #f7f7f7;
    max-width: 90%;
    margin: auto;
  }

  .single-row-chart {
    height: 490px;
    margin-left: 12px;
    margin-right: 12px;
  }

  .w-full {
    width: 100%;
  }
</style>
