<script>
    import { Doughnut } from "vue-chartjs"

    export default {
        extends: Doughnut,
        props: {
            options: {
                type: Object
            },
            dataInput: {
                type: Array,
                default: () => []
            }
        },
        data: () => {
            return {
                chartData: [0,0,0]
            }
        },
        // mounted() {
        //     this.renderChart({
        //         labels: ['Sin responder', 'Respondidos con OK', 'Respondidos con Baja'],
        //         datasets: [{
        //             label: 'data',
        //             data: this.chartData,
        //             backgroundColor: [
        //                 'rgb(255, 99, 132)',
        //                 'rgb(54, 162, 235)',
        //                 'rgb(255, 205, 86)'
        //             ],
        //         }]
        //     }, this.options)
        // },
        watch: {
            dataInput(value) {
                const labels = ['Sin responder', 'Respondidos con OK', 'Respondidos con Baja']
                this.renderChart({
                    labels: labels,
                    datasets: [{
                        label: 'data',
                        data: value,
                        backgroundColor: [
                            'rgb(255, 99, 132)',
                            'rgb(54, 162, 235)',
                            'rgb(255, 205, 86)'
                        ],
                    }]
                }, this.options)

            }
        }
    }
</script>