<template>
        <v-card 
        :color=cardColor
        height="80px" 
        width="200px"
        class="d-flex align-center justify-center ma-3"
        >
            <div>
                <slot name="title"></slot>
                <slot name="stat"></slot>
            </div>
        </v-card>
</template>

<script>
    export default {
        props: {
            cardColor: {
                type: String
            }
        }
    }
</script>

<style scoped>
    div {
        text-align: center;
        color: white;
    }
</style>